import { useAuthStore } from "~/stores/auth";

export default defineNuxtRouteMiddleware((to, from) => {
    const { user, address } = useAuthStore();

    if (!user) {
        return navigateTo('/auth/login');
    }

    if (!address) {
        return navigateTo('/address');
    }

});